@import '~bootstrap/scss/bootstrap';

@import "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/devicon.min.css";

@import 'https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap';

@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css";

$primary: hsl(111, 68%, 21%);
$contrast: hsl(111, 68%, 92%);
$complimentary: hsl(291, 68%, 21%);

body {
  background-color: $primary;
  color: $contrast;
}

h1, h2 {
  font-family: "Dosis", sans-serif;
  font-weight: bold;
}

@keyframes marqueeScroll {
    to {
      transform: translate3d(0, 0, 0);
    }
  }


.skill-icon {
    font-size: 5rem;
    transition: transform 0.3s ease; /* Smooth transition on hover */
}
  
.skill-icon:hover {
  transform: translateY(-5px); /* Move the icon up slightly on hover */
  filter: brightness(1.2); /* Increase brightness on hover */
}

.socials, a.socials {
  font-size: 3rem;
}

.socials:hover, a.socials:hover {
  transform: translateY(-5px); /* Move the icon up slightly on hover */
  filter: brightness(1.2); /* Increase brightness on hover */
}
